import axios from "axios";
import moment from "moment/moment";
import React from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
const apiUrl = process.env.REACT_APP_API_URL;

const columns = [
  {
    name: "Prospect Name",
    selector: (row) => row.firstname,
    sortable: true,
  },
  {
    name: "Created At",
    selector: (row) => moment(row.createdAt).format("DD/MM/YYYY"),
    sortable: true,
  },
  {
    name: "Mobile No",
    selector: (row) => row.phone,
    sortable: true,
  },
  {
    name: "Campaign Name",
    selector: (row) => row.campaignName,
    sortable: true,
  },  
  {
    name: "Assigned To",
    selector: (row) => row.assignTo,
    sortable: true,
  },
  {
    name: "Disposition",
    selector: (row) => row.disposition,
    sortable: true,
  },

  {
    name: "Call Status",
    selector: (row) =>
      row.disposition === "Lead Generation" ? "Pending" : "Connected",
    sortable: true,
  },
];

// function ProspectTable() {
//   const [selectedRows, setSelectedRows] = React.useState(false);
//   const [toggledClearRows, setToggleClearRows] = React.useState(false);
//   const [data, setData] = React.useState({});
//   const [disposition, setDisposition] = React.useState("All");
//   const [campaign, setCampaign] = React.useState("All");
//   const [agent, setAgent] = React.useState("All");
//   const [page, setPage] = React.useState(1);
//   const countPerPage = 10;
//   const handleChange = ({ selectedRows }) => {
//     setSelectedRows(selectedRows);
//   };

//   const userRole = localStorage.getItem("role");

// // Get agentEmail and role from localStorage
// const agentEmail = localStorage.getItem("agent_email");
// const role = localStorage.getItem("role");
// const [selectedAgent, setSelectedAgent] = React.useState(agentEmail || "All"); // State to track the selected agent

// // Fetch data based on disposition, selected agent, and campaign
// const fetchData = async (fDisposition = "All", agentEmail, campaignName, pageNo = 1) => {
//   try {
//     // Adjust API request based on role and selected agent
//     const emailParam = role === "admin" ? (selectedAgent === "All" ? "All" : selectedAgent) : agentEmail;
//     const res = await axios.get(
//       `${apiUrl}/get_all_prospects?disposition=${fDisposition}&agentEmail=${emailParam}&campaignName=${campaignName}&page=${pageNo}&perPage=${countPerPage}`
//     );
//     if (res.status === 200) {
//       setData(res.data.data);
//     }
//     console.log(res.data);
//   } catch (err) {
//     console.log(err);
//   }
// };

// // Pagination request
// const onPageRequest = (page) => {
//   setPage(page);
//   fetchData(disposition, agentEmail, campaign, page);
// };

// // Effect to fetch data when disposition, agentEmail, campaign, or selectedAgent changes
// React.useEffect(() => {
//   fetchData(disposition, agentEmail, campaign);
// }, [disposition, agentEmail, campaign, selectedAgent]);
//   const [filters, setFilters] = React.useState({});
//   const fetchFilters = async () => {
//     try {
//       const res = await axios.get(`${apiUrl}/get_all_prospects_filters`);
//       if (res.status === 200) {
//         setFilters(res.data.data);
//         console.log(res.data.data);
//       }
//       console.log(res.data);
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   React.useEffect(() => {
//     fetchFilters();
//   }, []);

//   const downloadCsv = async () => {
//     try {
//       await axios.get(
//         `${apiUrl}/download_prospects?agentEmail=${agentEmail}&campaignName=${campaign}&disposition=${disposition}`
//       );
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   return (
//     <div>
//       <div className="row">
//         <div className="col-12">
//           <div className="pros-head">
//             <h1>Prospects</h1>
//             <button onClick={() => fetchData("All", agentEmail, "All")}>Refresh</button>
//           </div>
//         </div>
//       </div>

//       <div className="pros-file-cont-filter">
//         <div className="row">
//           <div className="col-12 col-lg-5">
//             <div className="row">

//             {(userRole === "admin" ) && (
//               <div className="col">
//               <div className="filter-colmn">
//                 <label>Select By Agent</label>
//                 <br />
//                 <select
//                   onChange={(e) => setSelectedAgent(e.target.value)} // Update the selected agent
//                   value={selectedAgent} // Ensure the dropdown reflects the selected value
//                 >
//                   <option value="All">All</option>
//                   {filters.agents?.map((agent) => (
//                     <option key={agent._id} value={agent._id}>
//                       {agent._id}
//                     </option>
//                   ))}
//                 </select>
//               </div>
//             </div>

//                   )}
//               <div className="col">
//                 <div className="filter-colmn">
//                   <label>Select By Campaign</label>
//                   <br />
//                   <select onChange={(e) => setCampaign(e.target.value)}>
//                     <option value="All">All</option>
//                     {filters.campaigns?.map((campaign) => (
//                       <option key={campaign._id} value={campaign._id}>
//                         {campaign._id}
//                       </option>
//                     ))}
//                   </select>
//                 </div>
//               </div>
//             </div>
//           </div>
//           <div className="col-12 col-lg-7">
//             <div className="filter-colmn">
//               <label>Select Disposition</label>
//               <br />
//               <input
//                 type="radio"
//                 name="disposition"
//                 value="All"
//                 onChange={(e) => setDisposition(e.target.value)}
//               />
//               <label>{"  "}All</label>&nbsp;&nbsp;
//               <input
//                 type="radio"
//                 name="disposition"
//                 value="Callback"
//                 onChange={(e) => setDisposition(e.target.value)}
//               />
//               <label>{"  "}Callback</label>&nbsp;&nbsp;
//               <input
//                 type="radio"
//                 name="disposition"
//                 value="Interested"
//                 onChange={(e) => setDisposition(e.target.value)}
//               />
//               <label>{"  "}Interested</label>&nbsp;&nbsp;
//               <input
//                 type="radio"
//                 name="disposition"
//                 value="Not Interested"
//                 onChange={(e) => setDisposition(e.target.value)}
//               />
//               <label>{"  "}Not Interested</label>&nbsp;&nbsp;&nbsp;&nbsp;
//               {(userRole === "admin" ) && (
//               <button>
//                 <a
//                   href={`${apiUrl}/download_prospects?agentEmail=${selectedAgent}&agentid=${selectedAgent}&campaignName=${campaign}&disposition=${disposition}`}
//                 >
//                   Download CSV
//                 </a>
//               </button>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>

//       <div>
//         <DataTable
//           columns={columns}
//           data={data.prospects}
//           selectableRows
//           pagination
//           paginationServer
//           paginationTotalRows={data.total}
//           onSelectedRowsChange={handleChange}
//           onChangePage={onPageRequest}
//           clearSelectedRows={toggledClearRows}
//         />
//       </div>
//     </div>
//   );
// }

function ProspectTable() {
  const [data, setData] = React.useState({});
  const [filteredData, setFilteredData] = React.useState({});
  const [disposition, setDisposition] = React.useState("All");
  const [campaign, setCampaign] = React.useState("All");
  const [agent, setAgent] = React.useState("All");
  const [page, setPage] = React.useState(1);
  const [startDate, setStartDate] = React.useState(""); // State for start date
  const [endDate, setEndDate] = React.useState(""); // State for end date
  const countPerPage = 10;
  const [selectedRows, setSelectedRows] = React.useState(false);
  const [toggledClearRows, setToggleClearRows] = React.useState(false);
  const userRole = localStorage.getItem("role");
  const agentEmail = localStorage.getItem("agent_email");
  const role = localStorage.getItem("role");
  const [selectedAgent, setSelectedAgent] = React.useState(agentEmail || "All");
  const [loading, setLoading] = React.useState(true); // Loading state
  const [filters, setFilters] = React.useState({});

  const handleChange = ({ selectedRows }) => {
    setSelectedRows(selectedRows);
  };
const onPageRequest = (page) => {
  setPage(page);
  fetchData(disposition, agentEmail, campaign, page);
};
  // Fetch all data
  
const fetchData = async (fDisposition = "All", agentEmail, campaignName, pageNo = 1) => {
  try {
    // Adjust API request based on role and selected agent
    const emailParam = role === "admin" ? (selectedAgent === "All" ? "All" : selectedAgent) : agentEmail;
    const res = await axios.get(
      `${apiUrl}/get_all_prospects?disposition=${fDisposition}&agentEmail=${emailParam}&campaignName=${campaignName}&page=${pageNo}&perPage=${countPerPage}`
    );
    if (res.status === 200) {
      setData(res.data.data);
      setFilteredData(res.data.data);
    }
    console.log(res.data);
  } catch (err) {
    console.log(err);
  }
};
  // Apply date filtering on the fetched data
  // const applyDateFilter = () => {
  //   let filtered = data.prospects; // Assuming 'data.prospects' is the array of prospects
  
  //   // Apply date filter only if both startDate and endDate are set
  //   if (startDate && endDate && Array.isArray(filtered)) {
  //     filtered = filtered.filter((prospect) => {
  //       const prospectDate = new Date(prospect.createdAt);
  //       return (
  //         prospectDate >= new Date(startDate) &&
  //         prospectDate <= new Date(new Date(endDate).setHours(23, 59, 59, 999))
  //       );
  //     });
  //   }
  
  //   setFilteredData({
  //     ...data, // Keep other data like 'total' intact
  //     prospects: filtered, // Update only the filtered prospects
  //   });
  // };
  
  const applyDateFilter = () => {
   
    if (!data.prospects || !Array.isArray(data.prospects)) return;
  
    let filtered = [...data.prospects]; 
  
   
    if (startDate && endDate) {
      const start = new Date(startDate).setHours(0, 0, 0, 0); // Set to start of day
      const end = new Date(endDate).setHours(23, 59, 59, 999); // Set to end of day
  
      filtered = filtered.filter((prospect) => {
        const prospectDate = new Date(prospect.createdAt).getTime();
        return prospectDate >= start && prospectDate <= end;
      });
    }
  
   
    setFilteredData({
      ...data,
      prospects: filtered,
    });
  };
  
  // Trigger fetchData when filters change
 
  React.useEffect(() => {
    fetchData(disposition, agentEmail, campaign);
  }, [disposition, agentEmail, campaign, selectedAgent]);

  // Trigger applyDateFilter whenever startDate or endDate changes
  React.useEffect(() => {
    applyDateFilter(); // Apply the filter when dates are updated
  }, [startDate, endDate, data]); // Ensure it runs when data changes

  const fetchFilters = async () => {
    try {
      const res = await axios.get(`${apiUrl}/get_all_prospects_filters`);
      if (res.status === 200) {
        setFilters(res.data.data);
        console.log(res.data.data);
      }
      console.log(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  React.useEffect(() => {
    fetchFilters();
  }, []);

  return (
    <div>
      <div className="row">
        <div className="col-12">
          <div className="pros-head">
            <h1>Prospects</h1>
            <button onClick={() => fetchData("All", agentEmail, "All", 1)}>Refresh</button>
          </div>
        </div>
      </div>

      <div className="pros-file-cont-filter">
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="row">
              {userRole === "admin" && (
                <div className="col">
                  <div className="filter-colmn">
                    <label>Select By Agent</label>
                    <br />
                    <select onChange={(e) => setSelectedAgent(e.target.value)} value={selectedAgent}>
                      <option value="All">All</option>
                      {filters.agents?.map((agent) => (
                        <option key={agent._id} value={agent._id}>
                          {agent._id}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
              <div className="col">
                <div className="filter-colmn">
                  <label>Select By Campaign</label>
                  <br />
                  <select onChange={(e) => setCampaign(e.target.value)}>
                    <option value="All">All</option>
                    {filters.campaigns?.map((campaign) => (
                      <option key={campaign._id} value={campaign._id}>
                        {campaign._id}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="row">
              <div className="col">
                <div className="filter-colmn">
                  <label>Start Date</label>
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="col">
                <div className="filter-colmn">
                  <label>End Date</label>
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-12">
            <div className="filter-colmn" style={{marginTop:15, marginLeft:10}}>
              <label>Select Disposition</label>
              <br />
              <input
                type="radio"
                name="disposition"
                value="All"
                onChange={(e) => setDisposition(e.target.value)}
                checked={disposition === "All"}
              />
              <label>{"  "}All</label>&nbsp;&nbsp;
              <input
                type="radio"
                name="disposition"
                value="Callback"
                onChange={(e) => setDisposition(e.target.value)}
                checked={disposition === "Callback"}
              />
              <label>{"  "}Callback</label>&nbsp;&nbsp;
              <input
                type="radio"
                name="disposition"
                value="Interested"
                onChange={(e) => setDisposition(e.target.value)}
                checked={disposition === "Interested"}
              />
              <label>{"  "}Interested</label>&nbsp;&nbsp;
              <input
                type="radio"
                name="disposition"
                value="Not Interested"
                onChange={(e) => setDisposition(e.target.value)}
                checked={disposition === "Not Interested"}
              />
              <label>{"  "}Not Interested</label>&nbsp;&nbsp;&nbsp;&nbsp;
                            {(userRole === "admin" ) && (
              <button>
                <a
                  href={`${apiUrl}/download_prospects?agentEmail=${selectedAgent}&agentid=${selectedAgent}&campaignName=${campaign}&disposition=${disposition}`}
                >
                  Download CSV
                </a>
              </button>
              )}
            </div>
          </div>
        </div>

      </div>

      <div>
      <DataTable
            columns={columns}
            data={filteredData.prospects} // Use the filtered data
            selectableRows
            pagination
            paginationServer
            paginationTotalRows={filteredData.total} // Update total rows
            onSelectedRowsChange={handleChange}
            onChangePage={(page) => fetchData(disposition, agentEmail, campaign, page)}
            clearSelectedRows={selectedRows}
          />
      </div>
    </div>
  );
}

export default ProspectTable;